import React from 'react';

const App = () => {
  return (
    <div>
      <h1>Hello, PLEASE HELP ME!</h1>
       <h2>Happy Crying!!</h2>
    </div>
  );
};

export default App;